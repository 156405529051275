/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "babel-polyfill";
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
// axios
import axios from "axios";
// bootstrap
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.css";
// Views
import "views";
import "../../components";
// Parsley
import "javascripts/parsley.config";
import "parsleyjs/dist/parsley";
import "javascripts/parsley.custom_validator";
import "parsleyjs/dist/i18n/ja";
import "parsleyjs/dist/i18n/ja.extra";

import "@fortawesome/fontawesome-free/js/all";

// moment
import "moment";
// swiper
import "swiper/dist/js/swiper.min";
// select2
import "../javascripts/select2";
// clipboard
import "../javascripts/clipboard";
// scripts for uuuo-ai
import "../javascripts/uuuo_ai";

import "./application.scss";

// import "../javascripts/selling_informations_header";

Rails.start();
ActiveStorage.start();

global.$ = require("jquery");

const csrfToken = document
  .getElementsByName("csrf-token")[0]
  .getAttribute("content");

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
axios.defaults.headers.common.Accept = "application/json";
